<template>
  <div class="v-pa-md">
    <div class="v-mb-sm">
      <a-button type="primary" icon="form" @click="handleApplyProject">去申报项目</a-button>
    </div>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-card :body-style="{ padding: 0 }">
          <span slot="title">待办事项（{{ taskList.length }}）</span>
          <div style="height: 210px">
            <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="taskLoading" :columns="taskCols" :data-source="taskList" rowKey="key" :scroll="{ y: 170 }">
              <template slot="action" slot-scope="text, record">
                <router-link :to="{ path: 'project/apply', query: { id: record.projectId } }" class="hover-line text-indigo" title="编辑" v-if="record.type === 1">去处理</router-link>
                <router-link :to="{ path: 'project/declare', query: { id: record.projectId } }" class="hover-line text-indigo" title="编辑" v-else-if="record.type === 2">去处理</router-link>
                <router-link :to="{ path: 'project/view', query: { id: record.projectId } }" class="hover-line text-indigo" title="编辑" v-else-if="record.type === 3">去上传</router-link>
              </template>
              <template slot="state" slot-scope="text, record">
                <span class="text-red" v-if="record.type === 1">待提交</span>
                <span class="text-red" v-else-if="record.type === 2">未上传</span>
              </template>
              <template slot="projectName" slot-scope="text, record">
                <router-link class="hover-line text-indigo" :title="text || record.topic" :to="{ path: 'project/view', query: { id: record.projectId } }">{{ text || record.topic }}</router-link>
              </template>
              <template slot="taskName" slot-scope="text, record">
                <span class="text-red font-xs" v-if="record.checkResult === 0">【已驳回】</span>{{text}}
              </template>
            </a-table>
          </div>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card :body-style="{ padding: 0 }" title="项目文档">
          <div class="stat-container">
            <div class="info-stat justify-around" style="height: 210px">
              <div class="stat-item">
                <div ref="noReview" class="stat-value text-indigo">
                  <span class="text-red hover-line" ref="overdue" @click="$router.push('/usercenter/document/list?tab=unchecked')">0</span>
                  <i>份</i>
                </div>
                <div class="stat-field">逾期未交</div>
              </div>
              <div class="stat-item">
                <div ref="noReply" class="stat-value text-primary">
                  <span class="text-orange hover-line" ref="unsubmit" @click="$router.push('/usercenter/document/list?tab=unchecked')">0</span>
                  <i>份</i>
                </div>
                <div class="stat-field">未提交</div>
              </div>
              <div class="stat-item">
                <div ref="noReply" class="stat-value text-primary">
                  <span class="text-indigo hover-line" ref="submitted" @click="$router.push('/usercenter/document/list?tab=checked')">0</span>
                  <i>份</i>
                </div>
                <div class="stat-field">已提交</div>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="v-my-md" :gutter="16">
      <a-col :span="12">
        <a-card :body-style="{ padding: 0 }" title="项目统计">
          <div class="stat-container">
            <div class="info-stat justify-around" style="height: 200px">
              <div class="stat-item">
                <div ref="noReview" class="stat-value text-indigo">
                  <span class="hover-line text-dark" ref="applying" @click="$router.push('project/list?tab=unstarted')">0</span>
                  <i>个</i>
                </div>
                <div class="stat-field">申报中项目</div>
              </div>
              <div class="stat-item">
                <div ref="noReply" class="stat-value text-primary">
                  <span class="hover-line text-orange" ref="ongoing" @click="$router.push('project/list?tab=started')">0</span>
                  <i>个</i>
                </div>
                <div class="stat-field">执行中项目</div>
              </div>
              <div class="stat-item">
                <div ref="noReply" class="stat-value text-primary">
                  <span class="hover-line text-indigo" ref="closed" @click="$router.push('project/list?tab=closed')">0</span>
                  <i>个</i>
                </div>
                <div class="stat-field">已结束项目</div>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card :body-style="{ padding: 0 }" title="项目资助金额">
          <div class="info-stat justify-around v-px-md" style="height: 200px">
            <div class="stat-item">
              <div ref="noReview" class="stat-value text-primary">
                <span class="text-red" ref="amount">0</span>
                <i>元</i>
              </div>
              <div class="stat-field">项目资助金额合计</div>
            </div>
            <div class="stat-item2">
              <div class="flex items-center">
                <div class="stat-field v-mr-xs">已收款金额合计</div>
                <div ref="noReview" class="stat-value text-indigo">
                  <span style="font-size: 16px" ref="amountReceived">0</span>
                  <i>元</i>
                </div>
              </div>
              <div class="flex items-center v-my-xs">
                <div class="stat-field v-mr-xs">未收款金额合计</div>
                <div ref="noReview" class="stat-value text-indigo">
                  <span style="font-size: 16px" ref="amountUnreceived">0</span>
                  <i>元</i>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { fetchById } from '@/api/user'
import { getTasks, getReports, getProjects } from '@/api/main'
import gsap from 'gsap'
export default {
  name: 'ProjectDashboard',
  data() {
    return {
      projectLoading: false,
      taskLoading: false,
      statLoading: false,
      projectStats: {},
      subjectChartOption: {},
      districtChartOption: {},
      taskList: [],
      taskCols: [
        {
          title: '事项名称',
          ellipsis: true,
          dataIndex: 'taskName',
          scopedSlots: { customRender: 'taskName' },
        },
        {
          title: '项目名称/议题',
          ellipsis: true,
          dataIndex: 'projectName',
          scopedSlots: { customRender: 'projectName' },
        },
        // {
        //   title: '项目板块',
        //   ellipsis: true,
        //   width: 100,
        //   dataIndex: 'subject',
        // },
        {
          title: '创建时间',
          dataIndex: 'date',
          width: 130,
          align: 'center',
          customRender: text => (text ? text.substring(0, 16) : ''),
        },
        // {
        //   title: '事项状态',
        //   dataIndex: 'state',
        //   width: 75,
        //   align: 'center',
        //   scopedSlots: { customRender: 'state' },
        // },
        {
          title: '操作',
          dataIndex: 'action',
          width: 60,
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      statInfo: {
        overdue: 0,
        unsubmit: 0,
        submitted: 0,
        applying: 0,
        ongoing: 0,
        closed: 0,
        amount: 0,
        amountReceived: 0,
        amountUnreceived: 0,
      },
    }
  },
  methods: {
    init() {
      // this.loadOngoingProject()
      this.loadTasks()
      this.loadReports()
      this.loadProjects()
    },
    loadTasks() {
      getTasks().then(({ data }) => {
        if (data.success) {
          let list = data.result.map((task, key) => Object.assign({ key }, task))
          list.sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime()
          })
          this.taskList = list
        }
      })
    },
    loadReports() {
      let vm = this
      getReports().then(({ data }) => {
        if (data.success) {
          let list = data.result
          let statInfo = {
            overdue: list.filter(item => [0, -1].includes(item.state) && item.restDays < 0).length,
            unsubmit: list.filter(item => [0, -1, 1].includes(item.state)).length,
            submitted: list.filter(item => item.state === 2).length,
          }
          gsap.to(vm.statInfo, 0.75, {
            ...statInfo,
            onUpdate: () => {
              Object.keys(statInfo).forEach(k => {
                let num = vm.statInfo[k]
                if (typeof num === 'number') {
                  if (vm.$refs[k]) {
                    vm.$refs[k].innerHTML = vm.$utils.thousands(num, 0)
                  }
                }
              })
            },
          })
        }
      })
    },
    loadProjects() {
      let vm = this
      this.loading = true
      let params = {
        // pageNum,
        pageSize: -1,
        // states: '7,-1',
        // creator: this.userinfo.userName,
      }
      getProjects(params)
        .then(({ data }) => {
          if (data.success) {
            let list = data.result.records
            let statInfo = {
              applying: list.filter(item => [0, 1, 2, 3, 4].includes(item.state)).length,
              ongoing: list.filter(item => [5, 6].includes(item.state)).length,
              closed: list.filter(item => [7, -1].includes(item.state)).length,
              amount: list
                .filter(item => [4, 5, 6, 7].includes(item.state))
                .map(item => item.amount || 0)
                .reduce((a, b) => a + b),
            }
            // console.log(statInfo)
            gsap.to(vm.statInfo, 0.75, {
              ...statInfo,
              onUpdate: () => {
                Object.keys(statInfo).forEach(k => {
                  let num = vm.statInfo[k]
                  if (typeof num === 'number') {
                    if (vm.$refs[k]) {
                      vm.$refs[k].innerHTML = vm.$utils.thousands(num, 0)
                    }
                  }
                })
              },
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleApplyProject() {
      let { id } = this.userinfo
      fetchById(id).then(({ data }) => {
        if (data.success) {
          let user = data.result
          if (user.state === 2) {
            this.$router.push({ path: '/usercenter/profile', query: { refer: 'project' } })
          } else {
            this.$router.push('/usercenter/project/apply')
          }
        }
      })
    },
    loadStatDashboard() {
      /*
      this.statLoading = false
      statDashboard().then(({ data }) => {
        if (data.success) {
          let { amount, overduePlan, overdueReport } = data.result
          let projectAmountOwn = amount.find(item => item.type === 1).amount
          let projectAmountFunded = amount.find(item => item.type === 2).amount
          let projectAmountOther = amount.find(item => item.type === 9).amount
          let statInfo = {
            overduePlan,
            overdueReport,
            projectAmountAll: projectAmountOwn + projectAmountFunded + projectAmountOther,
            projectAmountOwn,
            projectAmountFunded,
            projectAmountOther,
          }
          let vm = this
          gsap.to(vm.statInfo, 0.75, {
            ...statInfo,
            onUpdate: () => {
              Object.keys(statInfo).forEach(k => {
                let num = vm.statInfo[k]
                if (typeof num === 'number') {
                  if (vm.$refs[k]) {
                    vm.$refs[k].innerHTML = vm.$utils.thousands(num, 0)
                  }
                }
              })
            },
          })
        }
        this.statLoading = false
      })
      */
    },
  },
  mounted() {
    this.init()
  },
}
</script>
